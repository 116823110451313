import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import {  FiUsers , FiChevronUp, FiMail,FiPieChart, FiBook, FiTarget, FiAward, FiGlobe, FiCamera, FiLayout} from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const ServiceList1 = [
    {
        icon: <FiUsers />,
        title: 'Search Engine Optimization (SEO)',
        description: 'Boost your online presence and organic traffic with our SEO strategies tailored for Shopify stores.'
    },
    {
        icon: <FiMail />,
        title: 'Email Marketing Campaigns',
        description: 'Engage your audience and drive conversions through targeted email marketing campaigns integrated into your Shopify store.'
    },
    {
        icon: <FiPieChart />,
        title: 'Analytics & Reporting',
        description: 'Gain valuable insights into your e-commerce performance with detailed analytics and reporting solutions.'
    },
];


const ServiceList2 = [
    {
        icon: <FiBook />,
        title: 'E-commerce Strategy',
        description: 'Craft a winning e-commerce strategy tailored to your business goals and customer needs.'
    },
    {
        icon: <FiTarget />,
        title: 'Market Research',
        description: 'Gain a competitive edge by leveraging in-depth market research and industry analysis.'
    },
    {
        icon: <FiAward />,
        title: 'Brand Development',
        description: 'Define and strengthen your brand identity to create lasting connections with your customers.'
    },
];


const ServiceList3 = [
    {
        icon: <FiGlobe />,
        title: 'Custom Web Design',
        description: 'Transform your vision into reality with stunning custom web design and user-friendly interfaces.'
    },
    {
        icon: <FiCamera />,
        title: 'Visual Content Creation',
        description: `Enhance your brand's visual appeal with high-quality photography and multimedia content.`
    },
    {
        icon: <FiGlobe />,
        title: 'Custom Development',
        description: 'Leverage our expertise in custom development to create unique features and functionality for your Shopify store.'
    },
];


const ServiceList4 = [
    {
        icon: <FiGlobe />,
        title: 'Shopify Theme Customization',
        description: `Tailor your Shopify theme to your brand's unique style and requirements.`
    },
    {
        icon: <FiLayout />,
        title: 'Branding Services',
        description: 'Craft a compelling brand identity that resonates with your audience and sets you apart.'
    },
    {
        icon: <FiLayout />,
        title: 'Responsive Design',
        description: 'Ensure that your Shopify store is optimized for a seamless experience on all devices.'
    },
];

class Service extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Service' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Service'}   />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Digital Marketing</h2>
                                    <p>Harness the full potential of Shopify's internal marketing tools to streamline your digital marketing efforts<br /></p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList1.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Strategy</h2>
                                    <p>Collaborate with the client to develop and refine e-commerce strategies<br /> including marketing campaigns, sales promotions, and product launches.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList2.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Creative Agency</h2>
                                    <p>I specialize in creative solutions that bring your e-commerce dreams to life. I'm here to design,<br />  develop, and optimize your shopify store, ensuring it stands out in the digital marketplace.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList3.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Development</h2>
                                    <p>I'm dedicated to crafting Shopify solutions that  <br /> not only meet your business needs but also exceed your expectations.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList4.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;
