import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About',
        description =`Welcome to RavenFire Code, a dynamic and innovative Shopify web agency dedicated to helping businesses thrive in the digital world.
            At RavenFire Code, I have a passion for design, a flair for innovation, and a deep understanding of the Shopify platform.             What sets me apart is my commitment to excellence. I don't just build websites; I create digital storefronts that captivate, engage, and convert. My intent is to tailor your Shopify store to reflect your brand's personality and vision.
           
            So, whether you're an emerging brand looking to make your mark or an established business aiming to elevate your online presence, I am here to turn your e-commerce dreams into reality.
            Let's embark on this journey together. Contact me today, and let's create a digital masterpiece that tells your brand's unique story and drives growth in the digital era.`

         return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Values</h3>
                                                <p>Values are at the core of how I operate. I believe in transparency, collaboration, and delivering measurable results. Your success is my success.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">E-Commerce</h3>
                                                <p> I understand the challenges of the ever-evolving e-commerce landscape, and I'm here to guide you every step of the way. From concept to launch, and beyond, my support is unwavering. We're invested in your success.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;
