import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Business Stratagy',
        description: 'I immerse myselves in your goals, analyze market trends, and devise strategies that ensure your business thrives and stays ahead of the competition.'
    },
    {
        icon: <FiLayers />,
        title: 'Website Development',
        description: `I'm passionate about web development. From designing sleek, user-friendly interfaces to implementing robust, scalable solutions, I create websites that not only look stunning but also deliver exceptional performance.`
    },
    {
        icon: <FiUsers />,
        title: 'Marketing & Reporting',
        description: `We can leverage data-driven insights to craft and execute effective marketing campaigns. Shopify's detailed reporting keeps you informed about your ROI and campaign success.`   },
    {
        icon: <FiMonitor />,
        title: 'E-commerce Solutions',
        description: `E-commerce is the heart of online business, and shopify is a leader in building and optimizing e-commerce platforms. Whether you're starting a new online store or looking to enhance your existing one, you're covered.`
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services',
        description = 'At RavenFire Code, I offer a range of specialized services designed to elevate your online presence and drive your business forward. Explore expertise in Business Strategy, Website Development, Marketing & Reporting, and discover how I can help your brand thrive in the digital era.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Request Custom Service</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
