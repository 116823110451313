import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Shopify Store Design',
        description: 'Crafting visually appealing and highly functional Shopify stores that captivate your customers.'
    },
    {
        icon: '02',
        title: 'Custom Theme Development',
        description: 'Building tailor-made Shopify themes to ensure your store stands out from the competition.'
    },
    {
        icon: '03',
        title: 'Shopify App Integration',
        description: 'Integrating powerful Shopify apps to enhance the functionality and user experience of your store.'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;
